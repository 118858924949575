import Vue from 'vue'
import Vuex from 'vuex'
// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth/Auth'
import user from './auth/User'
import contacts from './app/Contacts'
import userConfig from './app/UserConfig'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    user,
    contacts,
    userConfig,
  },
  strict: process.env.DEV,
})

export default store
