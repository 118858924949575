import router from '@/router'
import { getError } from '@/utils/helpers'
import AuthService from '@/services/AuthService'
import * as Sentry from '@sentry/vue'
import dayjs from 'dayjs'

export default {
  namespaced: true,
  state: {
    user: null,
    loading: false,
    error: null,
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_USER_ACCOUNT(state, account) {
      state.user.account = account
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_ERROR(state, error) {
      state.error = error
    },
  },

  actions: {
    logout({ commit }) {
      return AuthService.logout()
        .then(() => {
          commit('SET_USER', null)
          this.dispatch('contacts/clearContactList')
          Sentry.setUser(null)
          if (router.currentRoute.name !== 'login') {
            router.push({ name: 'login' })
          }
        })
        .catch(error => {
          commit('SET_ERROR', getError(error))
        })
    },
    async getAuthUser({ commit }) {
      commit('SET_LOADING', true)
      try {
        const response = await AuthService.getAuthUser()
        commit('SET_USER', response.data)
        commit('SET_LOADING', false)
        Sentry.setUser({ id: response.data.id, email: response.data.email })
        return response.data
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_USER', null)
        commit('SET_ERROR', getError(error))
        Sentry.setUser(null)
      }
      return null
    },
    async setAuthUserAccount({ commit }, account) {
      commit('SET_LOADING', true)
      commit('SET_USER_ACCOUNT', account)
      commit('SET_LOADING', false)
    },
    setAuthUser({ commit }, user) {
      commit('SET_LOADING', true)
      commit('SET_USER', user)
      commit('SET_LOADING', false)
    },
  },

  getters: {
    authUser: state => state.user,
    account: state => state.user.account,
    locations: state => state.user.account.locations,
    // eslint-disable-next-line eqeqeq
    locationById: state => id => state.user.account.locations.find(el => el.id == id),
    // eslint-disable-next-line eqeqeq
    recurringEventById: state => (locationId, recurringEventId) => state.user.account.locations.find(location => location.id == locationId)
      .recurring_events
      .find(recurringEvent => recurringEvent.id == recurringEventId),
    // eslint-disable-next-line eqeqeq
    singleEventById: state => (locationId, singleEventId) => state.user.account.locations.find(location => location.id == locationId)
      .single_events
      .find(singleEvent => singleEvent.id == singleEventId),
    communicationTemplates: state => state.user.account.communication_templates,
    // eslint-disable-next-line eqeqeq
    communicationTemplateById: state => id => state.user.account.communication_templates.find(el => el.id == id),
    isAdmin: state => (state.user ? state.user.isAdmin : false),
    error: state => state.error,
    loading: state => state.loading,
    loggedIn: state => !!state.user,
  },
}
