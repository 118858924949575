export default {
  namespaced: true,
  state: {
    showVideoGuide: true,
  },
  mutations: {
    initialiseStore(state) {
      if (localStorage.getItem('userConfig')) {
        const savedConfig = JSON.parse(localStorage.getItem('userConfig'))
        Object.entries(savedConfig).forEach(([name, value]) => {
          state[name] = value
        })
      }
    },
    setVariable(state, payload) {
      state[payload.name] = payload.value
      localStorage.setItem('userConfig', JSON.stringify(state))
    },
  },
  getters: {},
}
