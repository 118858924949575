import Vue from 'vue'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import store from '@/store'

dayjs.extend(utc)
dayjs.extend(timezone)

Vue.filter('formatDate', date => {
  if (date) {
    // the backend stores all dates/times in admins timezone
    // const user = store.getters['auth/authUser']
    return (dayjs(date)
      // .tz(user.timezone)
      .format('MMM D YYYY'))
  }
  return '-'
})

Vue.filter('formatDateReturnDayAndMonthOnly', date => {
  if (date) {
    return (dayjs(date).format('ddd, MMM D'))
  }
  return '-'
})

Vue.filter('formatTime', time => {
  //  const user = store.getters['auth/authUser']
  if (time) {
    const dateTime = `${dayjs().format('YYYY-MM-DD')}T${time}:00`
    return dayjs(dateTime).format('h:mm') + dayjs(dateTime).format('a').slice(0, 1)
  }
  return '-'
})

Vue.filter('renderSampleTemplate', template => {
  const user = store.getters['auth/authUser']
  const placeholers = {
    '{{first_name}}': 'John',
    '{{last_name}}': 'Smith',
    '{{email}}': 'example@gmail.com',
    '{{phone}}': '202-555-0147',
    '{{day}}': `${dayjs().add(1, 'days').format('MMM D YYYY')}`,
    '{{time}}': '9:30a',
    '{{dow}}': 'Wed',
    '{{campus_name}}': 'Downtown',
    '{{campus_location}}': '123 Street',
    '{{church_name}}': user.account.settings.general.church_name,
    '{{next_service}}': `${dayjs().add(3, 'days').format('MMM D YYYY')} at 1:30p`,
  }

  if (user.account.locations.length) {
    placeholers['{{campus_name}}'] = user.account.locations[0].name
    placeholers['{{campus_location}}'] = user.account.locations[0].address || ''
  }

  for (const [placeholder, value] of Object.entries(placeholers)) {
    // eslint-disable-next-line no-param-reassign
    template = template.replace(placeholder, value)
  }
  return template
})
