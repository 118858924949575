import * as API from './API'

const authBaseUrl = process.env.VUE_APP_API_URL

export default {
  async login(payload) {
    await API.apiClient({
      method: 'get',
      url: '/sanctum/csrf-cookie',
      baseURL: authBaseUrl,
    })
    return API.apiClient({
      method: 'post',
      url: '/login',
      baseURL: authBaseUrl,
      data: payload,
    })
  },
  logout() {
    return API.apiClient({
      method: 'post',
      url: '/logout',
      baseURL: authBaseUrl,
    })
  },
  async forgotPassword(payload) {
    await API.apiClient({
      method: 'get',
      url: '/sanctum/csrf-cookie',
      baseURL: authBaseUrl,
    })

    return API.apiClient({
      method: 'post',
      url: '/forgot-password',
      baseURL: authBaseUrl,
      data: payload,
    })
  },
  getAuthUser() {
    return API.apiClient.get('/v1/user')
  },
  async resetPassword(payload) {
    await API.apiClient({
      method: 'get',
      url: '/sanctum/csrf-cookie',
      baseURL: authBaseUrl,
    })

    return API.apiClient({
      method: 'post',
      url: '/reset-password',
      baseURL: authBaseUrl,
      data: payload,
    })
  },
  updatePassword(payload) {
    return API.apiClient({
      method: 'put',
      url: '/user/password',
      baseURL: authBaseUrl,
      data: payload,
    })
  },
  async registerUser(payload) {
    await API.apiClient({
      method: 'get',
      url: '/sanctum/csrf-cookie',
      baseURL: authBaseUrl,
    })

    return API.apiClient({
      method: 'post',
      url: '/register',
      baseURL: authBaseUrl,
      data: payload,
    })
  },
  updateUser(payload) {
    return API.apiClient({
      method: 'post',
      url: '/profile-information',
      baseURL: authBaseUrl,
      data: payload,
    })
  },
}
