import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dayjs from 'dayjs'

export const getError = error => {
  const errorMessage = 'API Error, please try again.'

  if (error.name === 'Fetch User') {
    return error.message
  }

  if (!error.response) {
    console.error('Server did not respond')
    return errorMessage
  }
  if (process.env.NODE_ENV === 'development') {
    console.error(error.response.data)
    console.error(error.response.status)
    console.error(error.response.headers)
  }
  if (error.response.data && error.response.data.errors) {
    return error.response.data.errors
  }

  return errorMessage
}

export const escapeHtmlAndConvertNlToBr = text => {
  const node = document.createTextNode(`${text}`)
  const p = document.createElement('p')
  p.appendChild(node)
  return p.innerHTML.replace(/(?:\r\n|\r|\n)/g, '<br>')
}

export const capitalizeFirstLetter = text => {
  if (text.length >= 1) {
    return text.charAt(0)
      .toUpperCase() + text.slice(1)
  }
  return ''
}

export const handleValidationError = function (error, vueInstance) {
  if (
    error.response
    && error.response.data
    && error.response.data.error === 'Validation'
    && error.response.data.validation_errors
  ) {
    const errors = error.response.data.validation_errors
    const keys = Object.keys(errors)
    const baseToastTimeout = 1000
    console.error(errors)
    // eslint-disable-next-line array-callback-return
    keys.map((key, index) => {
      vueInstance.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error',
          icon: 'BellIcon',
          text: `${capitalizeFirstLetter(key)} : ${errors[key]}`,
          variant: 'danger',
        },
      }, {
        timeout: baseToastTimeout * parseFloat(index + 2),
      })
      if (typeof vueInstance.$refs[key] !== 'undefined') {
        vueInstance.$refs[key].applyResult({
          errors: errors[key],
          valid: false,
          failedRules: {}, // should be empty since this is a non-rule error
        })
      } else {
        console.warn('[Reference error]', `Can't find a reference ${key} in `, [vueInstance.$refs])
      }
    })
  }
}

export const getNumberOfDaysUntil = function (date) {
  return parseInt(dayjs(date)
    .diff(dayjs(), 'day'), 10)
}

export const getNumberOfDaysLeft = function (user) {
  if (user && user.trial_ends_at) {
    const daysLeft = getNumberOfDaysUntil(user.trial_ends_at)
    if (daysLeft === 0) {
      return 'Trial ends today'
    } if (daysLeft === 1) {
      return '1 day remaining in your trial'
    }
    return `${daysLeft} days remaining in your trial`
  }
  return null
}
