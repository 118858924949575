import AccountService from '@/services/AccountService'
import { getError } from '@/utils/helpers'

export default {
  namespaced: true,
  state: {
    contactList: null,
    contactCount: null,
    loading: false,
    error: null,
  },
  getters: {
    contactList: state => state.contactList,
    contactCount: state => state.contactCount,
    error: state => state.error,
    loading: state => state.loading,
  },
  mutations: {
    SET_ERROR(state, error) {
      state.error = error
    },
    SET_LOADING(state, val) {
      state.loading = val
    },
    SET_CONTACT_DATA(state, val) {
      state.contactList = val.contact_list
      state.contactCount = val.count
    },
  },
  actions: {
    loadContactList({ commit }) {
      commit('SET_LOADING', true)
      return AccountService.getContactList()
        .then(response => {
          commit('SET_CONTACT_DATA', response.data.data)
          commit('SET_LOADING', false)
        })
        .catch(error => {
          commit('SET_ERROR', getError(error))
          commit('SET_LOADING', false)
        })
    },
    setUpdatedContactList({ commit }, contactList) {
      commit('SET_LOADING', true)
      commit('SET_CONTACT_DATA', { contact_list: contactList })
      commit('SET_LOADING', false)
    },
    clearContactList({ commit }) {
      commit('SET_LOADING', true)
      commit('SET_CONTACT_DATA', { contact_list: [] })
      commit('SET_LOADING', false)
    },
  },
}
