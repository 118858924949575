import * as API from '@/services/API'

export default {
  updateAccountSettings(data) {
    return API.apiClient.patch('/v1/account/settings', data)
  },

  getPostmarkSenderSignatureStatus() {
    return API.apiClient.get('/v1/account/sender-signature-status')
  },

  resendSenderSignatureEmail() {
    return API.apiClient.post('/v1/account/resend-sender-signature-email')
  },

  getContactList() {
    return API.apiClient.get('/v1/contacts')
  },

  getContact(contactId) {
    return API.apiClient.get(`/v1/contacts/${contactId}`)
  },

  updateContact(contactId, data) {
    return API.apiClient.patch(`/v1/contacts/${contactId}`, data)
  },
  createContact(data) {
    return API.apiClient.post('/v1/contacts', data)
  },

  deleteContact(contactId) {
    return API.apiClient.delete(`/v1/contacts/${contactId}`)
  },

  createLocation(data) {
    return API.apiClient.post('/v1/locations/', data)
  },

  updateLocation(locationId, data) {
    return API.apiClient.patch(`/v1/locations/${locationId}`, data)
  },

  deleteLocation(locationId) {
    return API.apiClient.delete(`/v1/locations/${locationId}`)
  },

  createRecurringEvent(data) {
    return API.apiClient.post('/v1/recurring-events/', data)
  },

  updateRecurringEvent(recurringEventId, data) {
    return API.apiClient.patch(`/v1/recurring-events/${recurringEventId}`, data)
  },

  deleteRecurringEvent(recurringEventId) {
    return API.apiClient.delete(`/v1/recurring-events/${recurringEventId}`)
  },

  createSingleEvent(data) {
    return API.apiClient.post('/v1/single-events/', data)
  },

  updateSingleEvent(singleEventId, data) {
    return API.apiClient.patch(`/v1/single-events/${singleEventId}`, data)
  },

  deleteSingleEvent(singleEventId) {
    return API.apiClient.delete(`/v1/single-events/${singleEventId}`)
  },

  getScheduleForLocation(locationId) {
    return API.apiClient.get(`/v1/locations/${locationId}/schedule`)
  },

  getScheduledVisitorsForRecurringEvent(recurringEventId, date) {
    return API.apiClient.get(`/v1/recurring-events/${recurringEventId}/${date}/visitors`)
  },

  createRecurringEventException(data) {
    return API.apiClient.post('/v1/recurring-event-exceptions', data)
  },

  deleteRecurringEventException(recurringEventExceptionId) {
    return API.apiClient.delete(`/v1/recurring-event-exceptions/${recurringEventExceptionId}`)
  },

  updateCommunicationTemplate(communicationTemplateId, data) {
    return API.apiClient.patch(`/v1/communication-templates/${communicationTemplateId}`, data)
  },

  getSetupIntent() {
    return API.apiClient.get('/v1/billing/setup-intent')
  },

  setPaymentMethod(paymentMethod) {
    return API.apiClient.post('/v1/billing/payment-method', { payment_method: paymentMethod })
  },

  setPlan(planId) {
    return API.apiClient.post('/v1/billing/subscription', { plan_id: planId })
  },

  cancelPlan() {
    return API.apiClient.delete('/v1/billing/subscription')
  },

  getPlans() {
    return API.apiClient.get('/v1/billing/plans')
  },

  async uploadFile(data) {
    return API.apiClient({
      method: 'post',
      url: '/v1/file-upload',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data,
    })
  },
  getMailchimpList() {
    return API.apiClient.get('/v1/integrations/mailchimp/audiences')
  },
  updateMailchimpIntegrationSettings(settings) {
    return API.apiClient.patch('/v1/integrations/mailchimp', settings)
  },
  updatePlanningCenterIntegrationSettings(settings) {
    return API.apiClient.patch('/v1/integrations/planning-center', settings)
  },
  disconnectPlanningCenterIntegration() {
    return API.apiClient.delete('/v1/integrations/planning-center')
  },
  verifyPlanningCenterIntegration() {
    return API.apiClient.post('/v1/integrations/planning-center/verify-connection')
  },
  getPlanningCenterWorkflows() {
    return API.apiClient.get('/v1/integrations/planning-center/workflows')
  },
  getEmbedCode() {
    return API.apiClient.get('/v1/account/embed-code')
  },
  getUpcomingServices() {
    return API.apiClient.get('/v1/account/upcoming-services')
  },
  getRecurringServiceVisitorsCount(recurringServiceId) {
    return API.apiClient.get(`/v1/recurring-events/${recurringServiceId}/visitors`)
  },
  getSingleServiceVisitorsCount(singleEventId) {
    return API.apiClient.get(`/v1/single-events/${singleEventId}/visitors`)
  },
  getLocationServicesVisitorsCount(locationId) {
    return API.apiClient.get(`/v1/locations/${locationId}/visitors`)
  },
  sendSupportMessage(data) {
    return API.apiClient.post('/v1/support/message', data)
  },
  sendFeatureRequest(data) {
    return API.apiClient.post('/v1/support/feature-request', data)
  },
}
