import * as Sentry from '@sentry/vue'

export default function auth({ to, next, store }) {
  const loginQuery = { path: '/auth/login', query: { redirect: to.fullPath } }
  const user = store.getters['auth/authUser']
  if (!user) {
    store.dispatch('auth/getAuthUser').then(() => {
      if (!store.getters['auth/authUser']) next(loginQuery)
      else next()
    })
  } else {
    Sentry.setUser({ id: user.data.id, email: user.data.email })
    next()
  }
}

export function getVpToken() {
  return document.cookie
    .split('; ')
    .find(row => row.startsWith('vp_token='))
    ?.split('=')[1]
}
